import React from 'react';
import '../../App.css';
import Intro from '../Intro';
import Project from '../Project';
import Cards from '../Cards';
import Footer from '../Footer';
import IntroMap from '../IntroMap';
import NewPanoramas from '../NewPanoramas';


function Home() {
  return (
    <>
      <Intro/>
      <Project />
      {/* <IntroMap /> */}
      <NewPanoramas />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
