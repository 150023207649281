// src/components/LanguageSelector.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css'; // Import the CSS file
import Cookies from 'js-cookie';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    Cookies.set('language', lng, { expires: 365 }); // Save the language in a cookie
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  const getCurrentLanguageLabel = () => {
    switch (i18n.language) {
      case 'en':
        return 'EN';
      case 'hu':
        return 'HU';
      default:
        return 'Language';
    }
  };

  return (
    <div className="language-selector">
      <button onClick={toggleDropdown} className="dropdown-button">
        <img
          src={`/images/${i18n.language === 'en' ? 'eng' : 'hun'}.png`}
          alt={i18n.language === 'en' ? 'English' : 'Hungarian'}
          className="flag-icon"
        />
        {getCurrentLanguageLabel()}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={() => changeLanguage('hu')}>
            <img src="/images/hun.png" alt="Hungarian" className="flag-icon" />
            HU
          </li>
          <li onClick={() => changeLanguage('en')}>
            <img src="/images/eng.png" alt="English" className="flag-icon" />
            EN
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
