import React from 'react';
import '../../App.css';
import AboutOwner from '../AboutOwner';


function About() {
  return (
    <>
      <AboutOwner/>
    </>
  );
}

export default About;
