import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PanoraMap from './components/pages/PanoraMap';
import About from './components/pages/About';
import Fireplaces from './components/pages/Fireplaces';
import Contribution from './components/pages/Contribution';
import HikingBlog from './components/pages/HikingBlog';
import PanoRedirect from './components/pages/PanoRedirect';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
      <Route path='/' exact element={<Home/>} /> 
      <Route path='/map' exact element={<PanoraMap/>} /> 
      <Route path='/about' exact element={<About/>} /> 
      <Route path='/contribute' exact element={<Contribution/>} /> 
      <Route path='/hikingblog' exact element={<HikingBlog/>} /> 
      <Route path='/pano/:place/:animate?' element={<PanoRedirect/>} /> 
      <Route path='/fireplaces' exact element={<Fireplaces/>} /> 
      </Routes>
    </Router>
  </>
  );
}

export default App;
