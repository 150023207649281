export const getHost = () => {
    return productionRun()
    //return localRun()
}

export const productionRun = () => {
    return window.location.host
}

export const localRun = () => {
    //return "localhost:5000"
    return "192.168.1.165:5000"
}

export const getBackendRoot = () => {
    return `https://${getHost()}/api`
}

export const getBackendApi = (resource) => {
    return `${getBackendRoot()}/${resource}`
}

export const getFormattedDate = (timestamp) => {
    const timestampMs = timestamp * 1000;
    const date = new Date(timestampMs);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

export const goToPanorama = (img, animate=false) => {
    //window.location.href = "https://" + window.location.host + "/api/panorama?image=" + image;
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = getBackendApi("panorama"); 

    const inputData1 = document.createElement('input');
    inputData1.type = 'hidden';
    inputData1.name = 'image';
    inputData1.value = img;
    form.appendChild(inputData1);

    const inputData2 = document.createElement('input');
    inputData2.type = 'hidden';
    inputData2.name = 'animate';
    inputData2.value = animate ? "True" : "False";
    form.appendChild(inputData2);

    document.body.appendChild(form);
    form.submit();
}
