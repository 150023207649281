import React from 'react';
import '../App.css';
import './Intro.css';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

function Intro() {

  const { t } = useTranslation(); // Use the hook to get the translation function


  return (
    <div className='intro-container'>
      <video src='/videos/PanoramaVideoLower30fpsLowBitrate.mp4' autoPlay loop muted />
      <h1>PanoraMap</h1>
      <p>{t('adventure_awaits')}</p>
      <div className='intro-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          destLink='/map'
        >
          {t('discover_map')}
        </Button>
      </div>
    </div>
  );
}

export default Intro;
