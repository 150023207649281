import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation(); // Use the hook to get the translation function

  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Panorama newsletter to be notified about new panorama scenes
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>{t('aboutproject')}</h2>
            <Link to='/sign-up'>{t('howitworks')}</Link>
            {/* <Link to='/'>Terms of Service</Link> */}
          </div>
          <div class='footer-link-items'>
            <h2>{t('contactus')}</h2>
            <Link to='/contribute'>E-mail</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>{t('contribute')}</h2>
            <Link to='/contribute'>{t('submitimages')}</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.instagram.com/phoenixshots5'>Instagram</Link>
            <Link to='https://www.youtube.com/@phoenixshots'>Youtube</Link>
            <Link to='https://www.tiktok.com/@phoenixshots'>TikTok</Link>
            {/* <Link to='https://hu.linkedin.com/in/david-bakos-0b75b2117'>LinkedIn</Link> */}
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div >
            <Link to='/' className='social-logo'>
            <img className='footer-logo-img' src='/images/panoramalogo_inverted.png' />
              PanoraMap
            </Link>
          </div>
          <small class='website-rights'>PanoraMap © 2024</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/phoenixshots5'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='https://www.youtube.com/@phoenixshots'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link tiktok'
              to='https://www.tiktok.com/@phoenixshots'
              target='_blank'
              aria-label='TikTok'
            >
              <i class='fab fa-tiktok' />
            </Link>

            {/* <Link
              class='social-icon-link twitter'
              to='https://hu.linkedin.com/in/david-bakos-0b75b2117'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
