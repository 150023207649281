import './Cards.css';
import CardItem from './CardItem';
import React, { useState, useEffect } from 'react';
import { goToPanorama, getBackendApi } from '../utils/navigationUtils';
import { useTranslation } from 'react-i18next';


function Cards() {
  const [popularPlaces, setPopularPlaces] = useState([]);
  const { t } = useTranslation(); // Use the hook to get the translation function

  useEffect(() => {
    // Fetch data from the backend endpoint
    const fetchData = async () => {
      try {
        fetch(getBackendApi("image_views"))
        .then(response => response.json())
        .then(data => setPopularPlaces(data));


        //const response = await fetch('https://localhost:5000/image_views');
        //const data = await response.json();
        //setPopularPlaces(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);  // Empty dependency array means this effect runs once after the initial render

  return (
    <div className='cards'>
      <h1>{t('checkmostviewed')}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {popularPlaces.slice(0, 5).map((place, index) => (
              <CardItem
                key={index}
                src={getBackendApi(`${place.thumb}`)}
                text={`${t('explorethis')}: ${place.name} - ${place.views} ${t('views')}`}
                label={place.name}
                path={getBackendApi(`images/${place.image}`)}
                handleClick={() => goToPanorama(place.image)}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;