import React from 'react';
import '../App.css';
import './IntroMap.css';
import PanoraMap from './pages/PanoraMap';

function Intro() {
  return (
    <div className='map_bg'>
      <div className="panoramap-component"><PanoraMap/></div>
      
    </div>
  );
}

export default Intro;
