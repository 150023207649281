import React from 'react';
import '../App.css';
import './Project.css';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

function Project() {

  const { t } = useTranslation(); // Use the hook to get the translation function

  return (
    <div className='project-container'>
        <h1>{t('about_panoramap')}</h1>
        <img src="/images/360.png" className='project-icon' alt="360"></img>
        <p>{t('360freedom')}</p>
        <div class="divider-line"></div>
        <img src="/images/flying.png" className='project-icon' alt="Flying"></img>
        <p>{t('likeflying')}</p>
        <div class="divider-line"></div>
        <img src="/images/portal.png" className='project-icon' alt="Navigate"></img>
        <p>{t('navigate')}</p>
        <div class="divider-line"></div>
        <img src="/images/vr.png" className='project-icon' alt="VR option"></img>
        <p>{t('tryvr')}</p>
        <div class="divider-line"></div>
        {/* <div className='hero-btns'>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            onClick={console.log('hey')}
          >
            {t('howto')} <i className='far fa-play-circle' />
          </Button>
        </div> */}
    </div>
  );
}

export default Project;
