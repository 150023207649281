import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { goToPanorama, getBackendApi } from '../../utils/navigationUtils';


function PanoRedirect() {
  const { place, animate } = useParams();

  const shouldAnimate = animate === 'animate';
  
  useEffect(() => {
    console.log('Place:', place); // Log the value of the place parameter
    // Make a REST call to fetch the image name associated with the place
    fetch(getBackendApi(`placetoimage/${place}`))
      .then(response => response.json())
      .then(data => {
        // Extract the image name from the response data
        const { imageName } = data;
        
        // Redirect to the panorama page with the image name
        goToPanorama(imageName, shouldAnimate);
      })
      .catch(error => {
        console.error('Error fetching image data:', error);
        // Handle error (e.g., display error message)
      });
  }, [place]);

  // Render null since this component doesn't render any UI
  return null;
};

export default PanoRedirect;