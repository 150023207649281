import '../../App.css';

import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { toGeoJSON } from 'togeojson';

// Import Leaflet CSS
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import { goToPanorama, getBackendApi } from '../../utils/navigationUtils';




function PanoraMap() {
  const [map, setMap] = useState(null);
  const [coordinates, setCoordinates] = useState([]);

  const flyToMapPoint = (map, lat, long) => {
       map.flyTo([lat, long], 14, {
          animate: true,
          duration: 10 // Duration in seconds
        });
  }

  useEffect(() => {
    // Initialize Leaflet map when component mounts
    const leafletMap = L.map('map').setView([47.1625, 19.5033], 8);
    // Add OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(leafletMap);

    let DefaultIcon = L.icon({
      iconUrl: icon,
      iconSize:     [25, 40],
      iconAnchor:   [20, 40],
      popupAnchor:  [-6, -50],
    });
  
    L.Marker.prototype.options.icon = DefaultIcon;

    // Set the map state
    setMap(leafletMap);

    return () => {
      // Clean up the map when component unmounts
      leafletMap.remove();
    };
  }, []);

  useEffect(() => {
    // Fetch data from backend API when component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(getBackendApi("get_images"));
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCoordinates(data.coordinates);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Render markers and popups when coordinates change
    if (map) {
      coordinates.forEach(coord => {
        const { img, thumb, lat, long, filename } = coord;
        const marker = L.marker([lat, long]).addTo(map);

        const popupContent = `<img src="${getBackendApi(thumb)}" alt="Popup Image ${thumb}" width="300">`;
        marker.bindPopup(popupContent, { maxWidth: 1000 });     // COMMENT TO FLY TO POINT
        //attachImageToMarker(marker, img, null);

        marker.on('mouseover', function () {
          this.openPopup();     // COMMENT TO FLY TO POINT
        });

        marker.on('mouseout', function () {
          this.closePopup();
        });
        
        marker.on("click", function (event) {
          goToPanorama(filename);     // COMMENT TO FLY TO POINT
          //flyToMapPoint(map, lat, long);
        });
        
      });
    }
  }, [map, coordinates]);

  return <div id="map" style={{ height: '100vh' }}>  </div>;
};


export default PanoraMap;
