import React from 'react';
import '../App.css';
import './AboutOwner.css';
import { useTranslation } from 'react-i18next';

function AboutOwner() {
  const { t } = useTranslation(); // Use the hook to get the translation function
  
  return (
    <div className="aboutowner-container">
      {/* Left Section - Picture */}
      <div className="left-section">
        <img
          src="/images/hiking.jpg" // Replace with the path to your image
          alt="Owner"
          className="owner-image"
        />
      </div>

      {/* Right Section - Description */}
      <div className="right-section">
        <div className="right-content">
          <h2 className="header">About me</h2>
          <p className="description">My name is David Bakos. I love traveling, hiking, discovering new places.</p> 
          
          <p className="description">I started flying with my drone in November, 2022. 
            I've been making aerial videos since then, mostly in the topic of nature, hiking scenes, panoramic places.</p> 

          <p className="description">I've always been fascinated about aerial 360° images that shows a certain place completely, so I decied to put all my panorama images on a map, to organized them.
            This has led to the project, PanoraMap...
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutOwner;
