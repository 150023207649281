import React from 'react';
import '../../App.css';
import './HikingBlog.css'
import { useTranslation } from 'react-i18next';

export default function HikingBlog() {
  const { t } = useTranslation(); // Use the hook to get the translation function

  return ( 
    <div className='hikingblog-container'>
        <h1 className='products'>{t('comingsoon')}</h1>
  </div>

)
}
