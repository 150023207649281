import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

// Translation files
import translationEN from './locales/en/translation.json';
import translationHU from './locales/hu/translation.json';

// i18next configuration
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      hu: {
        translation: translationHU,
      },
    },
    lng: Cookies.get('language') || 'hu', // default language
    fallbackLng: 'hu', // Fallback language if user language is not available
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
