import React from 'react';
import '../../App.css';
import './Contribute.css'
import { useTranslation } from 'react-i18next';

export default function Contribution() {
  const { t } = useTranslation(); // Use the hook to get the translation function

  return ( 
    <div className='contribute-container'>
        <h1 className='products'>{t('contribute')}</h1>
        <p>{t('contributetext')}</p>
        <div class="divider-line"></div>
        <p>panoramap.hungary@gmail.com</p>
  </div>

)
  

  
}
