import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import LanguageSelector from '../components/LanguageSelector';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation(); // Use the hook to get the translation function
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img className='navbar-img' src='/images/panoramalogo.png' />
            PanoraMap
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fa-solid fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              {t('home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/map' className='nav-links' onClick={closeMobileMenu}>
              {t('map')}
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                {t('about')}
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link to='/hikingblog' className='nav-links' onClick={closeMobileMenu}>
              {t('hikingblog')}
              </Link>
            </li>
            <li>
              <Link to='/contribute' className='nav-links-mobile' onClick={closeMobileMenu}>
              {t('contribute')}
              </Link>
            </li>
            {!button && (
              <li className='nav-item-mobile'>
                <LanguageSelector />
              </li>
            )}
          </ul>
          {button && <Button buttonStyle='btn--outline' destLink='/contribute'>{t('contribute')}</Button>}
          {button && <LanguageSelector />}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
